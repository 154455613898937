import React, { useEffect } from 'react';
import { navigate } from '../common/Utils';

const RedirectHome = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return <></>;
};

export default RedirectHome;
